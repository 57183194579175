<style>
  .container {
  background-color: #e4e4e4;
  height: 100%;
  display: grid; 
  align-items: flex-start;
  justify-content: center;
  }
  .button{
    background-color: #9dbacf;
    color: '#fff';
    padding: 10px 10px 10px 10px;;
  }
  .message{
    margin: 32px 0px;
  }
</style>



<div class="container">
  <h1 class="message">
    Simple Api
  </h1>
  <button class="button" (click)="getAllPost()" >get all Post</button>
  <button class="button" (click)="getPostById()" >get Post by Id</button>
  <button class="button" (click)="addPost()" >Add Post</button>
  <button class="button" (click)="updatePost()" >Update Post</button>
  <button class="button" (click)="deletePost()" >Delete Post</button>
</div>


<router-outlet></router-outlet>